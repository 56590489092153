import { SVGAttributes } from 'react'

export default function CustomerSupportIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} viewBox="0 0 61 49" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M39.5095 27.702H39.627H40.2145C45.7959 27.2907 50.261 21.2393 50.261 14.1304C50.261 4.55386 47.0885 0.5 39.627 0.5H39.5095C32.0481 0.5 28.8755 4.55386 28.8755 14.0716C28.8755 21.5331 33.6344 27.6432 39.5095 27.702ZM39.5683 1.38127L39.627 1.67503C46.3834 1.67503 49.086 5.25887 49.086 14.0716C49.086 20.8868 44.8559 26.4682 39.5683 26.4682H39.5095C34.2806 26.4094 30.0505 20.8868 30.0505 14.0716C30.0505 5.25887 32.8118 1.67503 39.5683 1.67503V1.38127Z" fill="white"/>
			<path d="M57.1349 32.2259C54.5498 30.1109 46.7359 26.9971 45.8546 27.1146C45.3846 27.1733 44.9146 27.5258 44.4445 27.9371C44.1508 28.1721 43.7983 28.4658 43.4458 28.6421C42.5057 29.1709 41.3307 29.3471 41.272 29.4059C41.272 29.4059 40.1557 29.5234 39.6269 29.5234C39.1569 29.5234 38.2169 29.4059 37.9819 29.4059C37.9231 29.4059 36.7481 29.2296 35.8081 28.6421C35.4556 28.4071 35.103 28.1721 34.8093 27.9371C34.2805 27.5258 33.8693 27.1733 33.3993 27.1146C32.4592 26.9971 24.704 30.1109 22.119 32.2259C19.2989 34.5173 19.0051 43.6825 19.0051 44.74C19.0051 47.7363 32.518 48.5001 39.6857 48.5001C46.8534 48.5001 60.3662 47.7363 60.3662 44.6813C60.2487 43.6825 59.955 34.5173 57.1349 32.2259ZM22.7065 33.166C25.2915 31.0509 32.4005 28.3483 33.1642 28.2896C33.3405 28.3483 33.693 28.5833 33.9868 28.8184C34.3393 29.0534 34.6918 29.4059 35.1618 29.6409C36.3368 30.2871 37.6881 30.5221 37.8056 30.5221C37.9819 30.5221 39.0394 30.6396 39.6269 30.6396C40.2732 30.6396 41.3895 30.5221 41.4482 30.5221C41.507 30.5221 42.917 30.2871 44.0333 29.6409C44.5033 29.4059 44.8558 29.0534 45.2083 28.8184C45.5021 28.5833 45.9133 28.2896 46.0308 28.2896C46.6771 28.2896 53.8448 30.9922 56.4886 33.166C58.5449 34.811 59.1324 42.0962 59.1912 44.74C59.1912 45.5038 52.4935 47.3251 39.6857 47.3251C26.8778 47.3251 20.1802 45.5038 20.1802 44.74C20.0627 44.6225 20.2977 35.1635 22.7065 33.166Z" fill="white"/>
			<path d="M17.8888 27.232H18.0063H18.5938C23.294 26.8208 26.9365 21.9444 26.9365 15.893C26.9365 7.96155 24.2927 4.55396 18.0063 4.55396H17.9476C11.7199 4.55396 9.01733 7.96155 9.01733 15.893C9.01733 22.1207 13.0124 27.232 17.8888 27.232ZM17.9476 5.43523L18.0063 5.72899C23.5877 5.72899 25.7615 8.60781 25.7615 15.893C25.7615 21.4744 22.2952 26.057 18.0063 26.057V26.3508L17.9476 26.057C13.6587 26.057 10.1924 21.4744 10.1924 15.893C10.1924 8.60781 12.3662 5.72899 17.9476 5.72899V5.43523Z" fill="white"/>
			<path d="M12.6012 27.7606C12.7187 27.7606 13.0125 28.0544 13.2475 28.2306C13.5412 28.4656 13.835 28.7006 14.2463 28.9356C15.245 29.5231 16.3613 29.6994 16.4788 29.6994C16.7138 29.6994 17.5363 29.8169 18.0063 29.8169C18.5351 29.8169 19.4751 29.6994 19.4751 29.6994C19.4751 29.6994 20.7089 29.5231 21.6489 28.9356C22.0015 28.7006 22.354 28.4656 22.6477 28.2306C22.8827 28.0544 23.1765 27.8193 23.2352 27.8193C23.4115 27.8193 24.469 28.1719 26.1141 28.8769L26.4078 28.9944L26.8778 27.8781L26.5841 27.7606C25.5265 27.2906 23.5877 26.5856 23.1765 26.6443C22.7652 26.7031 22.354 26.9968 21.9427 27.3493C21.7077 27.5256 21.4139 27.7606 21.1202 27.9369C20.4152 28.3481 19.4751 28.5244 19.3576 28.5244C18.8876 28.5831 18.3589 28.6419 18.0063 28.6419C17.5951 28.6419 16.8901 28.5831 16.6551 28.5244C16.5963 28.5244 15.6563 28.3481 14.8925 27.9369C14.5988 27.7606 14.305 27.5256 14.07 27.3493C13.6587 26.9968 13.2475 26.7031 12.8362 26.6443H12.7775C11.8962 26.6443 5.66853 29.0531 3.43597 30.9332C0.968405 32.7545 0.733398 40.3334 0.733398 41.2147C0.733398 41.9785 1.37967 43.036 7.01981 43.7998C11.2499 44.3873 15.95 44.446 17.3013 44.446H17.5951V43.271H17.3013C7.01981 43.2123 2.14344 41.8022 1.90843 41.2147C1.96718 39.0409 2.43719 33.0482 4.08224 31.6969C6.13854 30.0519 12.0724 27.7606 12.6012 27.7606Z" fill="white"/>
		</svg>
	)
}
