import { RequestDocument } from 'graphql-request/dist/types'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import useSWR from 'swr'

import { useDependencies } from '@/components/providers/DependencyProvider'
import { Author, Brand, CustomField, Image, Maybe, Money, PageInfo, Product, ProductAvailable, ProductPreOrder, ProductUnavailable, Review, Reviews } from '@/types/bigcommerce'

type BigCommerceResponse = { __typename?: 'Query' } & {
	site: { __typename?: 'Site' } & {
		products: {
			__typename?: 'ProductConnection',
		} & {
			edges?: Maybe<Array<Maybe<{
				__typename?: 'ProductEdge',
			} & {
				node: {
					__typename?: 'Product',
				} & Pick<Product, 'entityId' | 'sku' | 'name' | 'description' | 'path' | 'availabilityV2'> & {
					brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'name'>>,

					availabilityV2: ProductAvailable | ProductPreOrder | ProductUnavailable,

					images: {
						__typename?: 'ImageConnection',
					} & {
						edges?: Maybe<Array<Maybe<{
							__typename?: 'ImageEdge',
						} & {
							node: {
								__typename?: 'Image',
							} & Pick<Image, 'url' | 'altText' | 'isDefault'>,
						}>>>,
					},

					reviewSummary: {
						__typename?: 'Reviews',
					} & Pick<Reviews, 'numberOfReviews' | 'summationOfRatings'>,

					prices?: Maybe<{
						__typename?: 'Prices',
					} & {
						basePrice?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'value'>>,
						price: { __typename?: 'Money' } & Pick<Money, 'value'>,
					}>,

					customFields: { __typename?: 'CustomFieldConnection' } & {
						edges?: Maybe<Array<Maybe<{
							__typename?: 'CustomFieldEdge',
						} & {
							node: { __typename?: 'CustomField' } & Pick<CustomField, 'name' | 'value'>,
						}>>>,
					},

					reviews: { __typename?: 'ReviewConnection' } & {
						edges?: Maybe<Array<Maybe<{
							__typename?: 'ReviewEdge',
						} & {
							node: { __typename?: 'Review' } & Pick<Review, 'rating' | 'text' | 'title'> & {
								author: { __typename?: 'Author' } & Pick<Author, 'name'>,
							},
						}>>>,

						pageInfo: {
							__typename?: 'PageInfo',
						} & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>,
					},
				},
			}>>>,
		},
	},
}

const query = `
	query productsById($entityIds: [Int!]!) {
		site {
			products(entityIds: $entityIds) {
				edges {
					node {
						entityId

						sku
						name
						description
						path
						brand {
							name
						}

						availabilityV2{
							__typename
						}

						images {
							edges {
								node {
									url(width: 1280, height: 1280)
									altText
									isDefault
								}
							}
						}

						reviewSummary {
							numberOfReviews
							summationOfRatings
						}

						prices {
							basePrice {
								value
							}
				
							price {
								value
							}
						}

						customFields {
							edges {
								node {
									name
									value
								}
							}
						}
					}
				}
			}
		}
	}
	
`

export default function useFeaturedProducts(): Array<BigCommerceBasicProduct> | undefined {
	const { apiFetcher, bigcommerceFetcher } = useDependencies()
	const [ids, setIds] = useState<Array<number>>([])

	const { data } = useSWR<BigCommerceResponse>(
		ids.length === 0 ? null : [query, ids],
		async (query: RequestDocument, ids: Array<number>) => bigcommerceFetcher(query, { entityIds: ids }),
	)

	useEffectOnce(() => {
		void apiFetcher<{
			data: Array<number>,
		}>('/featured-products').then(response => setIds(response.data))
	})

	return data?.site.products.edges?.map<BigCommerceBasicProduct>(edge => {
		const product = edge!.node

		let image = product.images.edges?.find(image => image?.node.isDefault)
		if (image === undefined) {
			image = product.images.edges?.[0]
		}

		const basePrice = product.prices?.basePrice?.value ?? null
		const price = product.prices?.price.value ?? null

		let availability: BigCommerceBasicProduct['availability'] = 'available'
		if (product.availabilityV2.__typename === 'ProductPreOrder') {
			availability = 'preorder'
		} else if (product.availabilityV2.__typename === 'ProductUnavailable') {
			availability = 'disabled'
		}

		return {
			id: product.entityId,

			sku: product.sku,
			name: product.name,
			description: product.description,

			brand: product.brand?.name ?? null,

			reviewCount: product.reviewSummary.numberOfReviews,
			reviewTotal: product.reviewSummary.summationOfRatings,

			price: basePrice ?? price,
			sale_price: basePrice === price ? null : price,

			image: image ? {
				url: image.node.url,
				altText: image.node.altText,
			} : null,

			availability,
		}
	}).sort((a, b) => {
		const aIndex = ids.findIndex(id => id === a.id)
		const bIndex = ids.findIndex(id => id === b.id)

		return aIndex - bIndex
	})
}
