import Link from 'next/link'
import { HTMLAttributes, ReactNode } from 'react'

import image from '@/public/img/home/Dish-Programming.png'

export default function DishHero({ className, children }: { className?: HTMLAttributes<HTMLDivElement>['className'], children?: ReactNode}): JSX.Element {
	return (
		<div className={`bg-gradient-to-b from-[#6F91A2] to-[#1F2251] ${className ?? ''}`}>
			{ children }

			<div className="relative container mx-auto h-full flex flex-col-reverse justify-center items-center px-6 pt-2 pb-12 sm:flex-row sm:space-x-4 sm:pt-6 lg:px-16">
				<div className="mt-3 text-white max-w-sm mx-auto sm:max-w-full sm:w-3/5 md:w-1/2">
					<h2 className="text-4xl font-extrabold lg:text-6xl 2xl:text-7xl">DISH Programming</h2>
					<p className="mt-3 font-semibold md:mt-6 lg:text-xl 2xl:text-2xl">Cost-effective programming included with a single install of the DISH SMARTBOX. Offer your properties a wide range of premium channels.</p>

					<Link href="/dish-programming">
						<a className="mt-3 inline-block uppercase text-gray-800 bg-white rounded-full font-extrabold text-xs px-3 py-1.5 md:text-sm md:mt-6 lg:text-base lg:px-3.5 lg:py-1.5 2xl:px-4 2xl:py-2">Learn More</a>
					</Link>
				</div>
				<div className="max-w-sm sm:h-full mx-auto sm:max-w-full sm:w-2/5 md:w-1/2">
					<Link href="/dish-programming">
						<img className="w-full mx-auto object-contain max-w-xs sm:h-full sm:max-w-full" src={image} alt=""/>
					</Link>
				</div>
			</div>
		</div>
	)
}
