import { SVGAttributes } from 'react'

export default function FastShippingIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 48">
			<path d="M65.978 0H21.735a2.84 2.84 0 00-2 .832c-.516.512-.838 1.216-.838 1.856l-.452 2.752 1.29.192.452-2.88c0-.832.71-1.536 1.548-1.536h44.178c.838 0 1.548.704 1.548 1.408L60.883 41.92c0 .832-.71 1.536-1.548 1.536H38.632c.065-.384.065-.704.065-1.088 0-4.736-3.87-8.576-8.643-8.576-4.772 0-8.642 3.84-8.642 8.576 0 .384 0 .704.065 1.088h-6.32c-.84 0-1.549-.704-1.549-1.408l1.742-10.24-1.29-.192-1.742 10.368c0 1.536 1.29 2.816 2.838 2.816h7.868l-.193-.768a6.784 6.784 0 01-.194-1.6c0-4.032 3.29-7.296 7.353-7.296s7.352 3.264 7.352 7.296c0 .512-.064 1.088-.193 1.6l-.194.768h22.38a2.84 2.84 0 001.999-.832c.516-.512.838-1.216.838-1.856L68.751 2.88A2.772 2.772 0 0065.978 0z" fill="#fff"/>
			<path d="M30.119 36.736c-3.16 0-5.676 2.56-5.676 5.632 0 3.072 2.58 5.632 5.676 5.632s5.675-2.56 5.675-5.632c0-3.072-2.515-5.632-5.675-5.632zm0 9.984c-2.451 0-4.386-1.984-4.386-4.352 0-2.368 2-4.352 4.386-4.352s4.385 1.984 4.385 4.352c0 2.368-1.934 4.352-4.385 4.352zM76.813 36.736c-3.16 0-5.676 2.56-5.676 5.632 0 3.072 2.58 5.632 5.676 5.632 3.095 0 5.675-2.56 5.675-5.632 0-3.072-2.515-5.632-5.675-5.632zm0 9.984c-2.451 0-4.386-1.984-4.386-4.352 0-2.368 2-4.352 4.386-4.352s4.385 1.984 4.385 4.352c0 2.368-1.935 4.352-4.385 4.352z" fill="#fff"/>
			<path d="M87.325 15.232c-1.419-1.6-3.676-3.584-6.127-3.584H68.88l-5.224 31.04h5.417v-.32c0-4.224 3.483-7.616 7.675-7.616s7.74 3.392 7.74 7.616v.32h5.353l.064-.256c.129-.512 2.773-11.968 2.967-13.248.516-3.648-2-9.984-5.547-13.952zm-1.612 26.176c-.516-4.48-4.386-7.936-8.965-7.936s-8.449 3.456-8.965 7.936H65.14l4.773-28.48h11.35c1.549 0 3.354 1.088 5.16 3.136 3.354 3.776 5.676 9.6 5.224 12.992-.129.896-1.741 8.064-2.773 12.352h-3.16z" fill="#fff"/>
			<path d="M82.101 15.68h-9.674l-1.741 10.688h16.188a1.27 1.27 0 001.096-.576c.194-.256.258-.576.258-.96.065-2.112-3.676-9.152-6.127-9.152zm-9.867 9.408l1.29-8.128H82.1c.968 0 3.418 3.52 4.515 6.336.58 1.472.322 1.792.322 1.792H72.234zM24.314 13.312c1.548 0 2.838-1.28 2.838-2.816 0-1.536-1.29-2.816-2.838-2.816H2.838C1.29 7.68 0 8.96 0 10.496c0 1.536 1.29 2.816 2.838 2.816h21.476zM2.838 8.96h21.476c.839 0 1.548.704 1.548 1.536 0 .832-.71 1.536-1.548 1.536H2.838c-.839 0-1.548-.704-1.548-1.536 0-.832.71-1.536 1.548-1.536zM25.862 18.816c0-1.536-1.29-2.816-2.838-2.816H5.288c-1.547 0-2.837 1.28-2.837 2.88 0 1.536 1.29 2.816 2.837 2.816H22.96c1.612-.064 2.902-1.344 2.902-2.88zM5.288 17.28H22.96c.838 0 1.548.704 1.548 1.6 0 .832-.71 1.536-1.548 1.536H5.288c-.838 0-1.547-.704-1.547-1.6.064-.832.71-1.536 1.547-1.536zM24.572 27.264v-.128c0-1.536-1.225-2.752-2.773-2.752H8.771c-1.548 0-2.773 1.216-2.773 2.752v.128c0 1.536 1.225 2.752 2.773 2.752H21.8c1.483 0 2.773-1.28 2.773-2.752zm-15.8-1.6h13.027c.838 0 1.483.64 1.483 1.472v.128c0 .832-.645 1.472-1.483 1.472H8.771c-.838 0-1.483-.64-1.483-1.472v-.128c0-.832.645-1.472 1.483-1.472z" fill="#fff"/>
		</svg>
	)
}
