import { SVGAttributes } from 'react'

export default function Wave(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2560 567" preserveAspectRatio="none">
			<path d="M2560 566.5H0V38.8c12.6 7.6 417.5 114.5 772.3 114.5C1213.8 153.3 1354.4 0 1769.6 0c203.9 0 493.6 90.9 786.8 121.9 1.2.1 2.4.3 3.6.5v444.1z" fill="url(#wave-gradient-1)"/>
			<path d="M2560 566.5H0V63c12.6 7.6 417.5 114.5 772.3 114.5 441.5 0 582.1-153.3 997.3-153.3 203.9 0 503.8 96.1 786.8 121.9 1.2.1 2.4.3 3.6.5v419.9z" fill="url(#wave-gradient-2)"/>
			<g strokeWidth="12.755" strokeMiterlimit="10" strokeLinecap="round">
				<path d="M570.4 100.1c30.9 1.4 61.7 2.2 92.1 2.2 52.2 0 100.1-2.1 144.9-5.9" stroke="#8DC63F"/>
				<path d="M675.4 108.9c92 0 171-6.7 242.1-17.2" stroke="#6BAF45"/>
				<path d="M595.1 69.4c27.1 1.1 54.2 1.7 81 1.7" stroke="#79C2BE"/>
			</g>
			<defs>
				<linearGradient id="wave-gradient-1" x1="1280" y1="566.521" x2="1280" y2="0" gradientUnits="userSpaceOnUse">
					<stop stopColor="#79C2BE"/>
					<stop offset="0.8" stopColor="#6BAF45"/>
					<stop offset="1" stopColor="#8DC63F"/>
				</linearGradient>
				<linearGradient id="wave-gradient-2" x1="1280" y1="566.522" x2="1280" y2="24.183" gradientUnits="userSpaceOnUse">
					<stop offset=".5" stopColor="#fff" stopOpacity="0"/>
					<stop offset="1" stopColor="#fff" stopOpacity=".15"/>
				</linearGradient>
			</defs>
		</svg>
	)
}
