import { SVGAttributes } from 'react'

export default function SecurePaymentIcon(attributes: SVGAttributes<SVGSVGElement>): JSX.Element {
	return (
		<svg {...attributes} viewBox="0 0 63 49" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M58.0091 0.5H12.4122C9.63937 0.5 7.42114 2.71823 7.42114 5.49101V6.78498V14.6104V17.8761H8.65349V14.6104H61.7677V31.5552C61.7677 33.6502 60.1041 35.3139 58.0091 35.3139H26.4609V36.5462H58.0091C60.7819 36.5462 63.0001 34.328 63.0001 31.5552V14.6104V6.78498V5.49101C63.0001 2.71823 60.7819 0.5 58.0091 0.5ZM61.7677 6.78498H8.65349V5.49101C8.65349 3.39602 10.3172 1.73235 12.4122 1.73235H58.0091C60.1041 1.73235 61.7677 3.39602 61.7677 5.49101V6.78498ZM8.65349 8.01733H61.7677V13.378H8.65349V8.01733Z" fill="white"/>
			<path d="M56.7765 31.9865C57.4543 31.9865 58.0089 31.432 58.0089 30.7542V23.9762C58.0089 23.2985 57.4543 22.7439 56.7765 22.7439H48.9511C48.2733 22.7439 47.7188 23.2985 47.7188 23.9762V30.7542C47.7188 31.432 48.2733 31.9865 48.9511 31.9865H56.7765ZM56.7765 30.7542H48.9511V23.9762H56.7765V30.7542Z" fill="white"/>
			<path d="M26.7073 23.2367L13.7676 16.8901L0.766357 23.2367V23.6064C0.766357 38.0865 4.64826 41.1674 10.009 45.419C11.0565 46.2817 12.1656 47.1443 13.3363 48.1302L13.7676 48.4999L14.199 48.1302C15.3697 47.0827 16.4788 46.22 17.5263 45.419C22.887 41.1674 26.7689 38.0865 26.7689 23.6681V23.2367H26.7073ZM13.7676 18.2457L25.5366 23.9761C25.4133 37.532 21.9628 40.3048 16.6637 44.4331C15.7394 45.1726 14.7535 45.9736 13.706 46.8362C12.6585 45.9736 11.7343 45.1726 10.7484 44.4331C5.5109 40.3048 2.06032 37.532 1.99871 24.0378L13.7676 18.2457Z" fill="white"/>
		</svg>
	)
}
