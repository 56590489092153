import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'

import DeWaltHero from '@/components/heroes/DeWalt'
import DishHero from '@/components/heroes/Dish'
import MikroTikHero from '@/components/heroes/Mikrotik'
import UniFiHero from '@/components/heroes/UniFi'

export default function Hero(): JSX.Element {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const timeoutId = useRef<NodeJS.Timeout | null>(null)

	const heroes = [
		MikroTikHero,
		UniFiHero,
		DeWaltHero,
		DishHero,
	]

	useEffect(() => {
		timeoutId.current = setInterval(() => {
			setSelectedIndex(previousIndex => (previousIndex + 1) % heroes.length)
		}, 15000)

		return () => {
			if (timeoutId.current !== null) {
				clearInterval(timeoutId.current)
			}
		}
	}, [heroes.length])

	const selectHero: (index: number) => MouseEventHandler = useCallback((index: number) => () => {
		setSelectedIndex(index)

		if (timeoutId.current !== null) {
			clearInterval(timeoutId.current)
		}
	}, [])

	const children = (
		<>
			<div className="absolute w-full bottom-0 h-32 sm:h-24 md:h-32">
				<svg className="h-full transform -translate-x-1/2 sm:translate-x-0 md:w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2560 198" preserveAspectRatio="none">
					<path opacity=".15" d="M2560 196.9H0V38.4c375.8 10.9 549.4 114.4 912.3 114.4 595.3 0 741.5-117.8 977-117.8 253.6 0 292.2 79.8 670.7 108v53.9z" fill="#fff"/>
					<path opacity=".15" d="M0 197.4h2560v-24c-115.9 0-225.7-69.4-521.8-69.4-296.1 0-444.2 66.5-715.3 66.5-271.1 0-312.7-45.2-497-45.2s-209.3 49.9-373.1 49.9c-148.6 0-308.3-48.8-413.8-48.8-14.8 0-27.7.4-39 1.1v69.9z" fill="#fff"/>
					<g opacity=".4" stroke="#fff" strokeMiterlimit="10" clipPath="url(#hero-clip-1)">
						<path opacity=".64" d="M2560.1 63.8C2445.9 5 2226.8-11.3 2082.3 33.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 111.7 97 113.6.1 99.3"/>
						<path opacity=".64" d="M2560.1 69.8C2445.9 11 2226.8-5.3 2082.3 39.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 117.7 97 119.6.1 105.3"/>
						<path opacity=".64" d="M2560.1 75.8C2445.9 17 2226.8.7 2082.3 45.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 123.7 97 125.6.1 111.3"/>
						<path opacity=".64" d="M.1 117.3C97 131.6 303 129.7 461.4 76.7c195.8-65.4 491-85.3 924-9.3 361.1 63.3 586.8 17.7 696.9-16.2C2226.8 6.7 2446 23 2560.1 81.8"/>
						<path opacity=".64" d="M2560.1 87.8C2446 29 2226.8 12.7 2082.3 57.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 135.7 97 137.6.1 123.3"/>
						<path opacity=".64" d="M2560.1 93.8C2446 35 2226.8 18.7 2082.3 63.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 141.7 97 143.6.1 129.3"/>
						<path opacity=".64" d="M2560.1 99.8C2446 41 2226.8 24.7 2082.3 69.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 147.7 97 149.6.1 135.3"/>
						<path opacity=".64" d="M2560.1 105.8C2446 47 2226.8 30.7 2082.3 75.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 153.7 97 155.6.1 141.3"/>
						<path opacity=".64" d="M2560.1 111.8C2446 53 2226.8 36.7 2082.3 81.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 159.7 97 161.6.1 147.3"/>
						<path opacity=".64" d="M2560.1 117.8C2446 59 2226.8 42.7 2082.3 87.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 165.7 97 167.6.1 153.3"/>
						<path opacity=".64" d="M2560.1 123.8C2446 65 2226.8 48.7 2082.3 93.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 171.7 97 173.6.1 159.3"/>
						<path opacity=".64" d="M2560.1 129.8C2446 71 2226.8 54.7 2082.3 99.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 177.7 97 179.6.1 165.3"/>
						<path opacity=".64" d="M2560.1 135.8C2446 77 2226.8 60.7 2082.3 105.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 183.7 97 185.6.1 171.3"/>
						<path opacity=".64" d="M2560.1 141.8C2446 83 2226.8 66.7 2082.3 111.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 189.7 97 191.6.1 177.3"/>
						<path opacity=".64" d="M2560.1 147.8C2446 89 2226.8 72.7 2082.3 117.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 195.7 97 197.6.1 183.3"/>
						<path opacity=".64" d="M2560.1 153.8C2446 95 2226.8 78.7 2082.3 123.2c-110.1 33.9-335.8 79.5-696.9 16.2-433-76-728.2-56.1-924 9.3C303 201.7 97 203.6.1 189.3"/>
					</g>
				</svg>
			</div>
			<div className="absolute z-10 bottom-0 left-0 right-0 flex justify-center items-end pb-2 space-x-1">
				{ heroes.map((hero, index) => (
					<button className="p-2 rounded-full ring-offset-0" key={hero.name + String(index)} onClick={selectHero(index)}>
						<div className="border-white border rounded-full p-0.5">
							<div className={`h-2 w-2 rounded-full${selectedIndex === index ? ' bg-white' : ''}`} />
						</div>
					</button>
				))}
			</div>
		</>
	)

	return (
		<section className="overflow-hidden relative h-[34rem] sm:h-[22rem] lg:h-[28rem]">
			<svg>
				<defs>
					<clipPath id="hero-clip-1">
						<path fill="#fff" d="M0 0h2560.3v197.4H0z"/>
					</clipPath>
				</defs>
			</svg>
			{ heroes.map((Hero, index) => (
				<Hero className={`absolute inset-0 ${index === selectedIndex ? 'visible' : 'invisible'}`} key={Hero.name}>
					{ children }
				</Hero>
			))}
		</section>
	)
}
