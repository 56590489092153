import Link from 'next/link'

import Wave from '@/components/backgrounds/Wave'
import Carousel from '@/components/Carousel'
import Hero from '@/components/heroes'
import BulkDiscountIcon from '@/components/icons/home/BulkDiscount'
import CustomerSupportIcon from '@/components/icons/home/CustomerSupport'
import FastShippingIcon from '@/components/icons/home/FastShipping'
import SecurePaymentIcon from '@/components/icons/home/SecurePayment'
import ThirtyDayIcon from '@/components/icons/home/ThirtyDay'
import LoadingIcon from '@/components/icons/Loading'
import Layout from '@/components/Layout'
import Product from '@/components/Product'
import useFeaturedProducts from '@/hooks/useFeaturedProducts'
import AvProAdImg from '@/public/img/home/ads/AV-Pro-Ad.jpg'
import MilwaukeeAdImg from '@/public/img/home/ads/Milwaukee-Ad.jpg'
import AntennaBrandImg from '@/public/img/home/brands/Antenna.png'
import ArrowSVG from '@/public/img/home/brands/Arrow.svg'
import BoltsBrandImg from '@/public/img/home/brands/Bolts.png'
import CableBrandImg from '@/public/img/home/brands/Cable.png'
import DataBrandImg from '@/public/img/home/brands/Data.png'
import ElectricalBrandImg from '@/public/img/home/brands/Electrical.png'
import PpeBrandImg from '@/public/img/home/brands/PPE.png'
import RouterBrandImg from '@/public/img/home/brands/Router.png'
import SafteyBrandImg from '@/public/img/home/brands/Safety.png'
import SuppliesBrandImg from '@/public/img/home/brands/Supplies.png'
import ToolsBrandImg from '@/public/img/home/brands/Tools.png'
import VideoBrandImg from '@/public/img/home/brands/Video.png'
import CommercialImg from '@/public/img/home/Commercial-Solutions.jpg'
import MobileImg from '@/public/img/home/Mobile-Solutions.jpg'
import RetailImg from '@/public/img/home/Retail-Solutions.jpg'
import IconTreeSvg from '@/public/img/icon-tree.svg'
import { calculateUrl } from '@/utils/search'


function FeaturedProducts(): JSX.Element {
	const products = useFeaturedProducts()

	if (products === undefined) {
		return (
			<div className="py-32 mx-auto flex justify-center items-center">
				<LoadingIcon />
			</div>
		)
	}

	return (
		<div className="mt-10 px-6 sm:px-3">
			<Carousel options={{
				wrapAround: false,
				pageDots: false,
				groupCells: true,
				cellAlign: 'left',
				contain: true,
			}}>
				{ products.map(product => (
					<div key={product.id} className="w-full max-w-xs px-2 py-2 md:max-w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
						<Product product={{ ...product, kind: 'simple' }} />
					</div>
				))}
			</Carousel>
		</div>
	)
}


export default function Home(): JSX.Element {
	return (
		<>
			<Hero />

			<section className="bg-gray-800 text-white">
				<div className="container mx-auto px-3 py-1.5 flex justify-between space-x-8 md:px-6 md:py-2 xl:px-8 xl:py-3">
					<div className="flex justify-center items-center space-x-4 flex-1">
						<div>
							<FastShippingIcon className="h-9 lg:h-12"/>
						</div>

						<div className="shrink">
							<p className="text-xs font-bold">Fast Shipping</p>
							<p className="text-xs font-normal">Most orders placed by 2:00 PM ship same day</p>
						</div>
					</div>
					<div className="hidden justify-center items-center space-x-4 flex-1 sm:flex">
						<div>
							<ThirtyDayIcon className="h-9 lg:h-12"/>
						</div>

						<div className="shrink">
							<p className="text-xs font-bold">30 Day Returns</p>
							<p className="text-xs font-normal">30 day money back guarantee</p>
						</div>
					</div>
					<div className="hidden justify-center items-center space-x-4 flex-1 md:flex">
						<div>
							<CustomerSupportIcon className="h-9 lg:h-12"/>
						</div>

						<div className="shrink">
							<p className="text-xs font-bold">Customer Support</p>
							<p className="text-xs font-normal">A+ U.S. based customer service team</p>
						</div>
					</div>
					<div className="hidden justify-center items-center space-x-4 flex-1 xl:flex">
						<div>
							<BulkDiscountIcon className="h-9 lg:h-12"/>
						</div>

						<div className="shrink">
							<p className="text-xs font-bold">Bulk Discounts</p>
							<p className="text-xs font-normal">Have a big project? Call our sales team</p>
						</div>
					</div>
					<div className="hidden justify-center items-center space-x-4 flex-1 xl:flex">
						<div>
							<SecurePaymentIcon className="h-9 lg:h-12"/>
						</div>

						<div className="shrink">
							<p className="text-xs font-bold">Secure Payment</p>
							<p className="text-xs font-normal">24/7 safe and secure online ordering</p>
						</div>
					</div>
				</div>
			</section>

			<section className="container mx-auto text-base font-semibold text-center text-gray-800 md:text-lg">
				<div className="flex flex-wrap justify-center px-6 py-12 gap-x-12 gap-y-12 sm:gap-x-10 md:gap-x-24 md:px-12 md:py-16 md:justify-between xl:gap-x-8 xl:px-8 xl:py-24 2xl:py-28 2xl:gap-x-24">
					<Link href={`/search${calculateUrl('', 'Cabling', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={CableBrandImg} alt="Cableing" />
							<p className="mt-2">Cabling</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Data', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={DataBrandImg} alt="Data" />
							<p className="mt-2">Data</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('router', null, [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={RouterBrandImg} alt="Routers" />
							<p className="mt-2">Routers</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Video', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={VideoBrandImg} alt="Video" />
							<p className="mt-2">Video</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('antenna', null, [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={AntennaBrandImg} alt="Antennas" />
							<p className="mt-2">Antennas</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Electrical', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={ElectricalBrandImg} alt="Electrical" />
							<p className="mt-2">Electrical</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Tools', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={ToolsBrandImg} alt="Tools" />
							<p className="mt-2">Tools</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Fasteners', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={BoltsBrandImg} alt="Fasteners" />
							<p className="mt-2">Fasteners</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('safety', null, [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={SafteyBrandImg} alt="Safety" />
							<p className="mt-2">Safety</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'PPE Products', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={PpeBrandImg} alt="PPE" />
							<p className="mt-2">PPE Products</p>
						</a>
					</Link>
					<Link href={`/search${calculateUrl('', 'Supplies', [])}`}>
						<a>
							<img className="mx-auto rounded-full w-28 h-28 border-4 border-gray-150 md:w-40 md:h-40" src={SuppliesBrandImg} alt="Supplies" />
							<p className="mt-2">Supplies</p>
						</a>
					</Link>
					<Link href="/search">
						<a className="">
							<img className="mx-auto rounded-full w-28 h-28 border-4 bg-gray-150 border-gray-150 px-8 md:px-10 md:w-40 md:h-40" src={ArrowSVG} alt="Shop All" />
							<p className="mt-2">Shop All</p>
						</a>
					</Link>
				</div>
			</section>

			<section className="container mx-auto">
				<div className="px-4 sm:flex sm:px-6 xl:px-8">
					<div className="sm:w-1/2">
						<Link href={`/search${calculateUrl('', null, ['Milwaukee'])}`}>
							<a>
								<img className="w-full max-w-md mx-auto md:w-auto md:max-w-full" src={MilwaukeeAdImg} alt="Milwaukee Ad" />
							</a>
						</Link>
					</div>
					<div className="hidden sm:w-1/2 sm:block sm:ml-8 lg:ml-16">
						<Link href={`/search${calculateUrl('', null, ['AVPro Edge'])}`}>
							<a>
								<img className="mx-auto" src={AvProAdImg} alt="Av Pro Ad" />
							</a>
						</Link>
					</div>
				</div>
			</section>

			<section className="overflow-x-hidden">
				<div className="container mx-auto px-4 py-12 sm:px-6 md:py-16 xl:px-8 xl:py-24 2xl:py-28">
					<h3 className="text-center text-xl font-extrabold md:text-2xl xl:text-4xl">Featured Products</h3>

					<FeaturedProducts />

					<p className="mt-10 text-center text-lg font-normal text-gray-800 xl:text-2xl">Talk to one of our experts for more information: <a className="font-extrabold text-green-400 inline-block" href="tel:18004447223">1-800-444-7223</a></p>
				</div>
			</section>

			<section className="container mx-auto px-4 sm:px-6 xl:px-8 2xl:max-w-7xl">
				<div className="text-center">
					<p className="text-xl font-extrabold md:text-2xl lg:text-3xl xl:text-4xl">Your business is our business</p>
					<p className="mt-4 text-sm md:text-base lg:text-lg lg:mt-4 xl:text-xl">We know that no two companies – or projects – are alike. So, we take the details out of your hands by meeting <span className="inline-block">one-on-one</span> to discuss the intricacies of your request, then find a way to meet you with a flexible, budget-friendly solution that reduces your labor and turnaround time.</p>
				</div>

				<div className="mt-12 sm:flex md:justify-between xl:mt-20">
					<Link href="/retail">
						<a className="relative block overflow-hidden rounded-lg shadow-md w-60 mx-auto sm:w-full">
							<img src={RetailImg} alt="Reail Solutions" />
							<p className="absolute bottom-0 left-0 right-0 mx-auto text-center uppercase text-white font-extrabold py-2 bg-black bg-opacity-60 sm:text-xs md:text-sm lg:text-base xl:text-lg">Retail Solutions</p>
						</a>
					</Link>

					<Link href="/commercial">
						<a className="relative block overflow-hidden rounded-lg shadow-md w-60 mx-auto mt-6 sm:mt-0 sm:w-full sm:ml-4 md:ml-7 lg:ml-9 xl:ml-12">
							<img src={CommercialImg} alt="Commercial Solutions" />
							<p className="absolute bottom-0 left-0 right-0 mx-auto text-center uppercase text-white font-extrabold py-2 bg-black bg-opacity-60 sm:text-xs md:text-sm lg:text-base xl:text-lg">Commercial Solutions</p>
						</a>
					</Link>

					<Link href="/mobile">
						<a className="relative block overflow-hidden rounded-lg shadow-md w-60 mx-auto mt-6 sm:mt-0 sm:w-full sm:ml-4 md:ml-7 lg:ml-9 xl:ml-12">
							<img src={MobileImg} alt="Mobile Solutions" />
							<p className="absolute bottom-0 left-0 right-0 mx-auto text-center uppercase text-white font-extrabold py-2 bg-black bg-opacity-60 sm:text-xs md:text-sm lg:text-base xl:text-lg">Mobile Solutions</p>
						</a>
					</Link>
				</div>
			</section>


			<section className="mt-12 relative overflow-hidden sm:mt-16 xl:mt-24 2xl:mt-32">
				<div className="absolute inset-0 -mb-1 transform sm:translate-x-1/2">
					<Wave className="h-full transform -translate-x-1/4 sm:-translate-x-1/2 2xl:w-full" />
				</div>

				<div className="container mx-auto relative text-white">
					<div className="md:flex items-end md:px-6 xl:px-8 2xl:justify-center">
						<div className="pt-28 pb-4 px-4 md:px-0 md:w-1/2 md:max-w-full md:pt-32 md:pb-6 lg:pb-16 xl:pb-12 2xl:max-w-xl 2xl:pt-36 2xl:pb-10 2xl:mx-0">
							<h3 className="text-xl font-extrabold sm:text-2xl lg:text-3xl xl:text-4xl">Get Connected With Pace</h3>
							<p className="mt-2 text-sm sm:text-base lg:text-lg xl:mt-4">At Pace, we pride ourselves on being able to deliver the latest technology to you in a way personalized to your situation. We work directly with you to customize a solution that is right for you.</p>

							<Link href="/about-us">
								<a className="mt-3 inline-block uppercase text-gray-800 bg-white rounded-full font-extrabold text-xs px-3 py-1.5 md:text-sm md:mt-6 lg:text-base lg:px-3.5 lg:py-1.5 2xl:px-4 2xl:py-2">Learn More</a>
							</Link>
						</div>

						<div className="hidden md:block md:w-1/2 lg:pt-16 2xl:max-w-xl">
							<img src={IconTreeSvg} alt="Icon Tree" className="w-2/3 ml-auto lg:mx-auto xl:w-1/2" />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

Home.Layout = Layout
